import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { Link } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import io from 'socket.io-client';
import gsap from "gsap"
import { useGSAP } from '@gsap/react';
import Shery from 'sheryjs';
import Loader from '../../Containers/Loader';

ReactSession.setStoreType("localStorage");

const DigiPartNavBar = () => {
  const navigate = useNavigate();

  const socketRef = useRef(null);

  const [rand, setRandom] = useState();
  // const [idExist, verLogState] = useState(false);
  const [showPWD, setShowPWD] = useState(true);
  const [custType, setCustType] = useState(1);
  const [resetUserID, setResetUserID] = useState(null);
  const [eMail, setEMail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [fName, setFName] = useState(null);
  const [password1, setPassword1] = useState(null);

  const [messages, setMessages] = useState([]);
  // const userId = ReactSession.get("id");

  const [OTPVeri, setOTPVeri] = useState(false);

  const [forgetPassModal, setForgetPassModal] = useState(false);
  const forgetPassModalClose = () => setForgetPassModal(false);
  const forgetPassModalOpen = () => setForgetPassModal(true);

  const [isLoading, setIsLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);


  // const togglePWDVisibility = (value) => {
  //   var pwd = document.getElementById("DPpassword");

  //   if (showPWD) {
  //     pwd.type = "text";
  //   }
  //   else {
  //     pwd.type = "password";
  //   }
  //   setShowPWD(value);
  // }

  const togglePWDVisibilitySignIn = (value) => {
    var pwd1 = document.getElementById("password1");
    var pwd2 = document.getElementById("password2");

    if (showPWD) {
      pwd1.type = "text";
      pwd2.type = "text";
    }
    else {
      pwd1.type = "password";
      pwd2.type = "password";
    }
    setShowPWD(value);
  }


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  function CheckLogin() {
    var errorElements = [];
    var loginID = document.getElementById("DPloginID");
    var pwd = document.getElementById("DPpassword");
    var apiURL = 'http://43.205.199.21/userAPI?loginID=' + loginID.value + '&password=' + pwd.value;

    if (loginID.value === "")
      errorElements.push("loginID");

    if (pwd.value === "")
      errorElements.push("password");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      setIsLoading(true);
      const xhr = new XMLHttpRequest();
      xhr.open('GET', apiURL);
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      xhr.setRequestHeader('Authorization', 'token ABCDEFG56567');
      xhr.onload = function () {
        if (xhr.status === 200) {
          var partType = String(JSON.parse(xhr.responseText).isDigiPart);
          if (partType === "1" || partType === "2" || partType === "3") {
            ReactSession.set("dpsession", true);
            ReactSession.set("partnerType", partType);
            ReactSession.set("id", JSON.parse(xhr.responseText).id);
            ReactSession.set("username", JSON.parse(xhr.responseText).name);
            ReactSession.set("email", JSON.parse(xhr.responseText).email);
            ReactSession.set("mobile", JSON.parse(xhr.responseText).mobile);
            ReactSession.set("lastRec", JSON.parse(xhr.responseText).lastRec);
            ReactSession.set("is_verified", JSON.parse(xhr.responseText).is_verified);
            ReactSession.set("busi_name", JSON.parse(xhr.responseText).busi_name);
            ReactSession.set("pharNotiStatusRead", new Date());

            loginClose();
            navigate('/DPDashboard');
          }
          else {
            alert("Sorry, You are not our registered Medical Partner")
            loginClose();
          }
        }
        else {
          setIsLoading(false);
          document.getElementById("errorMessage").innerHTML = "Invalid Credentials........!";
          document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
          loginID.focus();
          return false;
        }
        setIsLoading(false);
      };
      xhr.send();
    }
  }

  //LOGIN MODAL CONTROL METHODS
  const [showLog, setLoginShow] = useState(false);
  const loginClose = () => setLoginShow(false);
  const loginShow = () => {
    setLoginShow(true);
    setShowSignUp(false);
    setShowVerifyOTP(false);
  }

  //SIGN UP MODAL CONTROL METHODS
  const [showSignup, setShowSignUp] = useState(false);
  const signupClose = () => setShowSignUp(false);
  const signupShow = () => {
    setShowSignUp(true);
    setLoginShow(false)
  }

  const generateOTP = () => {
    var rnd = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    setRandom(rnd);
    var mobileNo = document.getElementById("mobile").value;
    sendOTP(rnd, mobileNo);
    console.log("OTP :", rnd);
  }

  //Verify OTP Window Modal controls
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const verifyOTPClose = () => setShowVerifyOTP(false);

  const sendVerifyOTP = (verUserID, idToVeri) => {
    setIsLoading(true);
    if (idToVeri === "email")
      var verifyObject = { email: verUserID, };
    else
      var verifyObject = { mobile: verUserID, };

    fetch('http://43.205.199.21/OtpVerificationAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(verifyObject)
    }).then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status === "success") {
          // setShowSignUp(false);
          // alert("Please check your e-mail and enter your passcode.");
          setShowVerifyOTP(true);
        }
        else {
          alert("Error : ", data.status);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
      });
    // generateOTP();
  }


  // Method to verify OTP
  const verifySignUpOTP = (eleID) => {
    var otp2 = parseInt(document.getElementById("concatenatedOtp").value);
    var verID = eleID === "email" ? document.getElementById("eMail").value : document.getElementById("mobile").value;
    var verifyOTPMsg = document.getElementById("verifyOTPMsg");

    var apiURL = "http://43.205.199.21/OtpVerificationAPI?OTP=" + otp2
    if (eleID === "email")
      apiURL += ("&email=" + verID);
    else
      apiURL += ("&mobile=" + verID);

    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      if (resp.status === "emailSuccess") {
        setEmailVerified(true);
        verifyOTPMsg.innerText = "E-Mail is successfully verified, please verify mobile. A 6 Digit OTP has sent to your mobile, Please enter OTP";
        alert("E-Mail is successfully verified, Verify your mobile number now.");
        let otpInputs = document.querySelectorAll(".otpInputs > input");
        otpInputs.forEach((el, i) => {
          el.value = '';
        });
        sendVerifyOTP(document.getElementById("mobile").value, "mobile");
      }
      else if (resp.status === "mobileSuccess") {
        verifyOTPMsg.innerText = "Mobile is successfully verified.";
        registerUser();
      }
      else {
        verifyOTPMsg.innerText = "The OTP you have entered is incorrect, please enter correct OTP and retry."
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }


  // Verify OTP
  const verifyOTP = (eleID) => {
    var otp2 = parseInt(document.getElementById("concatenatedOtp").value);
    // var otp2 = parseInt(document.getElementById("otp2").value);
    // console.log("OTP : ",rand, "Entered OTP", otp2)
    if (rand === otp2) {
      registerUser();
      document.getElementById("OTPmsg").innerHTML = "Congrats, You are sucessfully registered, Login Now";
      document.getElementById("verifyBut")?.classList.replace("show", "hide");
      document.getElementById("logButinVerifyWin")?.classList.replace("hide", "show");
    }
    else
      document.getElementById("OTPmsg").innerHTML = "Incorrect OTP...!, Retry"
  }

  const verify_valid_user = () => {
    var userID = document.getElementById("mobile").value;
    setResetUserID(userID);
    var apiURL = 'http://43.205.199.21/varifylogIDAPI?email=' + userID + '&mobile=' + userID;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      if (resp.email < 1 && resp.mobile < 1) {
        document.getElementById("OTPmsg").innerHTML = "<p style='font-size: 14px; color: gray;'>The ID you have enetered is not registered with SHEET360ERP, <br /> Please enter a valid ID</p>";
        setIsOTPVerifyShow(false);
      }
      else {
        generateOTP();
        setIsOTPVerifyShow(true);
        document.getElementById("forgot-pwd-model-title").textContent = "Verify OTP";
        document.getElementById("OTPmsg").innerHTML = "<p style='font-size: 14px; color: gray;'>We have sent and OTP on your registered number</p>";
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  const verify_RecOTP = () => {
    var otp_rec = parseInt(document.getElementById("otp_rec").value);
    if (rand === otp_rec) {
      setOTPVeri(true);
      document.getElementById("OTPmsg").innerHTML = "<p style='color: gray; font-size: 14px; align-self: flex-start; display: flex; margin-left: 24px;'>Enter new password</p>";
    }
    else
      document.getElementById("OTPmsg").innerHTML = "<p style='color: red'>Incorrect OTP...!, Retry</p>"
  }


  const resetPassword = () => {
    var npwd1 = document.getElementById('Npassword1');
    var npwd2 = document.getElementById('Npassword2');

    if (npwd1.value.length < 8) {
      alert("Password must contain atleast 8 characters ");
    }
    else
      if (npwd1.value !== npwd2.value) {
        alert("Reset password doesn't match...!");
      }
      else {
        fetch('http://43.205.199.21/digiPartnerAPI/', {
          mode: 'cors',
          method: 'POST',
          headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          body: JSON.stringify({
            userId: resetUserID,
            npwd: npwd1.value,
            changePwd: 1,
          })
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              alert("Password is changed successfully");
              forgetPassModalClose();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
  }

  // React js Method for OTP SMS API call
  var data = null;
  const sendOTP = (otp, mobileNo) => {
    var url = "https://www.fast2sms.com/dev/bulkV2?authorization=kN1S64bWpOCJNCMePhXVifs9rKGrqVU3M0dNGC2rT4FjBMMfixOGNBS4ee1r&route=otp&sender_id=MMF&message=Sheet360ERP One Time Password &variables_values=" + otp + "&flash=0&numbers=" + mobileNo;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = function () {
      if (xhr.status === 200) {
        data = JSON.parse(xhr.responseText);
      }
    };
    xhr.send();
  }

  const verifyOTPWin = () => {
    var mobileNo = document.getElementById("mobile").value;
    document.getElementsByClassName("OTPmsg")[0].innerHTML = "<label>Please enter One Time Password sent on :" + mobileNo + "</label>";
  }

  // React js Method for register User API call
  const registerUser = (eleID) => {
    setIsLoading(true);
    fetch('http://43.205.199.21/userAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        email: eMail,
        mobile: mobile,
        name: fName,
        gender: false,
        dob: "2001/01/01",
        password: password1,
        digiPartner: custType === 1 ? 1 : custType === 2 ? 2 : 3,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        // console.log(data);
        if (data.status === "success" || data.status === "Already exist") {
          alert("You are successfully registered, login now")
          setEmailVerified(false);
          verifyOTPClose(false);
          signupClose();
          loginShow();
        }
        else {
          alert("Error in registering with Sheet360ERP");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
      });

  }

  //Validation Sign Up form  
  const validatRegForm = () => {
    var errorElements = [];
    // eMail mobile fName lName password1 password2
    var mailID = document.getElementById("eMail").value;
    var mobileNo = document.getElementById("mobile").value;
    setIsLoading(true);
    var apiURL = 'http://43.205.199.21/varifylogIDAPI?email=' + mailID + '&mobile=' + mobileNo;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      setIsLoading(false);
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (document.getElementById("eMail").value === "")
        errorElements.push("eMail");
      else
        if (!document.getElementById("eMail").value.match(validRegex)) {
          errorElements.push("eMail");
          document.getElementById("errorMessage").innerHTML = "Please enter a vallid E-mail ID";
        }
        else
          if (resp.email > 0) {
            errorElements.push("eMail");
            document.getElementById("errorMessage").innerHTML = "E-mail ID already exist..!";
          }

      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain 10 digits";
        }
        else
          if (resp.mobile > 0) {
            errorElements.push("mobile");
            document.getElementById("errorMessage").innerHTML += "<br />Mobile number already registered..!";
          }

      if (document.getElementById("fName").value === "")
        errorElements.push("fName");

      if (document.getElementById("password1").value === "")
        errorElements.push("password1");
      else
        if (document.getElementById("password1").value.length < 8) {
          errorElements.push("password1");
          document.getElementById("errorMessage").innerHTML += "<br />Password must contains 8 characters";
        }

      if (document.getElementById("password2").value === "")
        errorElements.push("password2");
      if (document.getElementById("password1").value !== document.getElementById("password2").value) {
        errorElements.push("password2");
        document.getElementById("errorMessage").innerHTML += "<br />Confirm Password Doesn't match";
      }

      errorElements.forEach(showError);
      function showError(item) {
        document.getElementById(item).classList.add("errorField");
      }

      if (errorElements.length > 0) {
        document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
        document.getElementById(errorElements[0]).focus();
        return false;
      }
      else {
        document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
        sendVerifyOTP(mailID, "email");
      }
    }).catch((Error) => {
      console.log("Error", Error);
      setIsLoading(false);
    });
  }


  const Sheet360ERPLogout = () => {
    ReactSession.remove("dpsession");
    ReactSession.remove("id");
    ReactSession.remove("username");
    ReactSession.remove("email");
    ReactSession.remove("mobile");
    ReactSession.remove("partnerType");
    ReactSession.remove("lastRec");
    ReactSession.remove("is_verified");
    ReactSession.remove("busi_name");
    ReactSession.remove("pharNotiStatusRead");
    navigate('/DigitalPartner');
    if (socketRef.current) {
      socketRef.current.close();  // Manually close the WebSocket
    }
  }


  // shery code

  Shery.makeMagnet(".digiNavLogo" /* Element to target.*/, {
    ease: "cubic-bezier(0.23, 1, 0.320, 1)",
    duration: 1,
  });

  function showDegiPartHomeLinks() {
    let degiPartHomeLinks = document.querySelector(".degiPartHomeLinks");
    let degiLinksMenuBtnIcon = document.querySelector(".degiLinksMenuBtnIcon");
    degiPartHomeLinks.classList.toggle("active");
    if (degiPartHomeLinks.classList.contains("active")) {
      degiLinksMenuBtnIcon.classList.replace("ri-menu-line", "ri-close-line")
    }
    else {
      degiLinksMenuBtnIcon.classList.replace("ri-close-line", "ri-menu-line")
    }
  }


  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }


  const handleOtpChange = (e) => {
    let otpInputs = document.querySelectorAll(".otpInputs > input");
    let otpValue = "";

    otpInputs.forEach((el, i) => {
      otpValue += el.value;
      el.classList.add("otpInput" + i);
      el.maxLength = 1; // Set max length to 1 for each input
    });

    // console.log(otpValue);

    if (e.keyCode !== 8) {
      if (e.target.value.length === 1) {
        if (!e.target.classList.contains("otpInput5")) {
          let nextInput = e.target.nextElementSibling;
          if (nextInput) {
            nextInput.focus();
          } else {
            console.log("complete");
          }
        }
      }
    } else {
      // console.log("Hit Back");
      let previousInput = e.target.previousElementSibling;
      if (previousInput) {
        previousInput.focus();
      }
      e.target.value = ""; // Clear the value of the current input on backspace
    }

    // Update the value of another input field with the concatenated OTP value
    document.getElementById("concatenatedOtp").value = otpValue;
  };

  useEffect(() => {
    if (ReactSession.get('dpsession')) {
      var userId = ReactSession.get("id");
      const socket = new WebSocket(`ws://43.205.199.21/notify/${userId}/`);

      socketRef.current = socket;  // Store the socket in the ref

      socket.onopen = () => {
        console.log('Connected to WebSocket');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        document.getElementById("notificationDialogue").classList.replace("hide", "show");
        setMessages((prevMessages) => [...prevMessages, data.message]);
        if (String(data.message).substring(0, 16) == "Profile verified") {
          ReactSession.set("is_verified", '1');
          setMessages(data.message);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        console.log('WebSocket closed:', event);
      };

      return () => {
        socket.close();
      };
    }
  }, []);

  const [time, setTime] = useState(10 * 60);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time => time - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [time]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const animeCon = useRef();

  useGSAP(() => {
    gsap.from(".congHead", {
      opacity: 0,
      duration: .9,
      y: 10,
      stagger: .9,
      repeat: -1
    })

  }, { scope: animeCon });

  const [isOTPVerifyShow, setIsOTPVerifyShow] = useState(false);

  return (
    <div ref={animeCon}>
      <div className='Dialogue hide' id="notificationDialogue">
        <div className='DialogueMSG' style={{ width: "45vw", height: "60vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <img style={{ width: 150, height: 150, position: "relative" }} src={require("../../../static/DPImages/balloons.gif")} alt="" />
          <h3 className='congHead' style={{ textAlign: "center", fontWeight: "700", color: "#00BAFF" }}>Congratulation</h3>
          <p style={{ textAlign: "center", fontWeight: "700" }}>{messages && messages !== null ? messages : ''}</p>
          {/* { messages.length > 0 ?
            messages.map((message, index) => (
              <li style={{textAlign:"center"}} key={index}>{message}</li>
          ))
          :''
          } */}

          {messages && String(messages).substring(0, 16) !== "Profile verified" ?
            <>
              <div className="timer">
                {formatTime(time)}
              </div>
              <p style={{ textAlign: "center", fontSize: 12, color: "gray", marginTop: 10 }}><b style={{ color: "red" }}>Note : </b> Your Order Will Be Auto Decline In 10 Minites. We Request You To Accept Order As Soon As Possible.</p>
            </>
            : null}


          <button onClick={() => {
            {
              document.getElementById("notificationDialogue").classList.replace("show", "hide");
              messages && String(messages).substring(0, 16) !== "Profile verified" ? navigate('/DigiPartnerPharmacyOrders') : navigate('/DPDashboard');
            }
          }}
            className='btnPrimary1' style={{ width: "50%", margin: "0px auto", border: 0, outline: 0, height: 35, borderRadius: 5 }}>
            {messages && String(messages).substring(0, 16) !== "Profile verified" ? "View Orders" : "Continue"}</button>

        </div>
        <div style={{ position: 'absolute', backgroundColor: "white", right: '29vw', zIndex: 99, top: '11vw', cursor: "pointer", borderRadius: '50%' }} onClick={() => {
          document.getElementById("notificationDialogue").classList.replace("show", "hide");
        }}>
          <img src={require('../../../static/images/Icons/close.png')} alt="" />
        </div>
      </div>

      <div className="digiNav">
        {
          ReactSession.get("dpsession") ?
            <i className="ri-menu-line degiLinksMenuBtnIcon" onClick={() => CollapseMenu(!menuCollapse)} style={{ fontSize: "20px" }}></i>
            :
            null
        }

        <Link to='/DigitalPartner' className='digiNavLogo'>
          <img id="digiLogo" src={require('../../../static/DPImages/Sheet360ERPLogo.png')} />
        </Link>

        <div className='digiNavTabs'>
          <div className='degiPartHomeLinks'>

            <Link to='/DigitalPartner'>Home</Link>
            <Link to="/aboutUs" >About Us</Link>
            <a href="#" onClick={() => { navigate('/ContactUs'); }}>Contact</a>
            {/* <a href="#" >Services</a> */}
            <Link to='/DPDashboard'>DashBoard</Link>
            {ReactSession.get("dpsession") ?
              <a href="#" onClick={() => Sheet360ERPLogout()}>Logout</a>
              :
              <div style={{ display: 'flex' }}>
                <a href="#" onClick={() => loginShow()}>Login</a>
                <a href="#" >|</a>
                <a href="#" onClick={() => signupShow()}>Registration</a>
              </div>
            }
          </div>


          <div className='digiMenuBtn' onClick={showDegiPartHomeLinks}>
            <img src={require('../../../static/DPImages/dotsMenu.png')} className='degiLinksMenuBtnIcon' />
            {/* <i className="ri-menu-line degiLinksMenuBtnIcon"></i> */}
          </div>

        </div>
      </div>

      {/* Login Model Definition */}
      <Modal show={showLog} className='digiDashHomeLoginModel' onHide={loginClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isLoading ?
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '9999999999', backgroundColor: 'rgba(157, 157, 157, 0.5)' }}>
              <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)', width: 100 }} src={require('../../../static/Loader/Spinner2.png')} alt="" />
            </div>
            : null
          }

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <div className="dpLogin" style={{ display: 'flex' }}>
                <img className='inputIcon' src={require('../../../static/DPImages/Icons/smartphone.png')} />
                <input className="digiPartLoginInput" name="DPloginID" id="DPloginID" type="text" onKeyPress={() => { removeErrorMessage("DPloginID") }} placeholder="Registered E-mail/Contact number" autoFocus />
              </div>
            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <div className="dpLogin" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <img style={{}} className='inputIcon' src={require('../../../static/DPImages/Icons/lock.png')} />
                <input className="digiPartLoginInput" name="DPpassword" id="DPpassword" type={showPWD ? "password" : "text"}
                  onKeyPress={() => { removeErrorMessage("DPpassword") }} placeholder="Password...." />
                <div className='togglePWdBtnIcon' onClick={() => setShowPWD(!showPWD)} >
                  <img src={showPWD ? require('../../../static/DPImages/Icons/hide.png') : require('../../../static/DPImages/Icons/show.png')} />
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="row digidashloginBottom">
            <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ display: 'flex', alignItems: 'center',fontSize:"14px" }}>
              <input type="checkbox" name="remember" id="remember" />&nbsp;Remember me
            </div>
            <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }}>
              <a style={{ color: '#00BAFF',fontSize:"14px" }} onClick={()=>{
                forgetPassModalOpen();
                setLoginShow(false)
                }}>Forgot Password?</a>
            </div>
          </div>

          <div className="loginLogo" style={{ marginTop: '1vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiSiginBtn" onClick={CheckLogin}>Login Now</div>
              </div>
            </div>
          </div>

          <div className="loginLogoCred" style={{ marginTop: '1.5vw' }}>
            <p>Don't have an account ? &nbsp;&nbsp;&nbsp;<p className="signInSignUpBtn" onClick={signupShow}>Sign Up</p></p>
          </div>
        </Modal.Body>
      </Modal>


      {/* SIGN UP MODAL */}
      <Modal show={showSignup} onHide={signupClose} className='digiDashHomeLoginModel' size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {isLoading ?
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '9999999999', backgroundColor: 'rgba(157, 157, 157, 0.5)' }}>
              <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)', width: 100 }} src={require('../../../static/Loader/Spinner2.png')} alt="" />
            </div>
            : null}

          <form name="userReg signUpForm" action="" method="post">
            <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin" style={{ display: 'flex' }}>
                  <img className='inputIcon' style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/email.png')} />
                  <input className="digiPartLoginInput" name="eMail" id="eMail" type="email" value={eMail}
                    onChange={(e) => setEMail(e.target.value)}
                    onKeyPress={() => { removeErrorMessage("eMail") }} placeholder="E-mail...." autoFocus />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin" style={{ display: 'flex' }}>
                  <img className="inputIcon" style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/smartphone.png')} />
                  <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="mobile" id="mobile" type="text" size="4" maxLength="10"
                    value={mobile} onChange={(e) => setMobile(e.target.value)}
                    onKeyPress={() => { removeErrorMessage("mobile") }} placeholder="Mobile number...." />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin" style={{ display: 'flex' }}>
                  <img className="inputIcon" style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/user.png')} />
                  <input className="digiPartLoginInput" name="fName" id="fName" type="text"
                    value={fName} onChange={(e) => setFName(e.target.value)}
                    onKeyPress={() => { removeErrorMessage("fName") }} placeholder="Full Name...." />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin" style={{ display: 'flex' }}>
                  <img className="inputIcon" style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/lock.png')} />
                  <input className="digiPartLoginInput" name="password1" id="password1" type={showPWD ? "password" : "text"}
                    value={password1} onChange={(e) => setPassword1(e.target.value)}
                    fNameonKeyPress={() => { removeErrorMessage("password1") }} placeholder="Password...." />
                  <div className='togglePWdBtnIcon' onClick={() => setShowPWD(!showPWD)} >
                    <img src={showPWD ? require('../../../static/DPImages/Icons/hide.png') : require('../../../static/DPImages/Icons/show.png')} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin" style={{ display: 'flex' }}>
                  <img className="inputIcon" style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/lock.png')} />
                  <input className="digiPartLoginInput" name="password2" id="password2" type={showPWD ? "password" : "text"} onKeyPress={() => { removeErrorMessage("password2") }} placeholder="Confirm Password...." />
                </div>
              </div>
            </div>

            <div className="row signUpFormBottom" style={{ marginTop: '2%' }}>
              <p style={{ fontSize: '95%', marginLeft: 10, padding: 0, marginTop: 10, marginBottom: 0 }}>Select Profile Type</p>
              <div style={{ fontSize: '85%', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <input name="custType" type="radio" id="retailer" onClick={() => {
                    setCustType(1);
                  }} style={{ marginTop: '.3rem' }} />&nbsp;
                  <label for='retailer' id='fileLabel' style={{ cursor: 'pointer' }}>
                    Retailer
                  </label>
                </div>

                <div>
                  <input name="custType" type="radio" id="wholeseller" onClick={() => {
                    setCustType(2);
                  }} style={{ marginTop: '.3rem' }} />&nbsp;
                  <label for='wholeseller' id='fileLabel' style={{ cursor: 'pointer' }}>
                    Whole Seller
                  </label>
                </div>

                <div>
                  <input name="custType" type="radio" id="both" onClick={() => {
                    setCustType(3);
                  }} style={{ marginTop: '.3rem' }} />&nbsp;
                  <label for='both' id='fileLabel' style={{ cursor: 'pointer' }}>
                    Both
                  </label>
                </div>
              </div>
            </div>
          </form>

          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiSiginBtn" onClick={validatRegForm}>Register</div>
              </div>
            </div>
            <p className='formInputCol' style={{ fontSize: '.65rem', marginTop: '.5vw' }}>By signing up, you agree to our <Link to="/termsCon">Terms and Conditions</Link> & <Link to="/privacyPolicy">Privacy Policy</Link></p>
          </div>
          <div className="loginLogoCred">
            <p className='formInputCol' style={{ fontSize: '.75rem' }}>Already have an account ? &nbsp;&nbsp;&nbsp;<p className="signInSignUpBtn" onClick={loginShow}>Login</p></p>
          </div>

          {/* custom otp modal */}
          {
            showVerifyOTP ?
              <div className="otpModalCon">
                <div className="otpModal">
                  <i className='closeBtn' onClick={() => setShowVerifyOTP(false)}><MdOutlineClose /></i>
                  <div className="OTPmsg" name="OTPmsg" id="OTPmsg" style={{ color: "red", marginBottom: 10 }}>
                    {/* <labe style={{color:"red"}}></labe> */}
                  </div>
                  <h2>Verify OTP</h2>
                  <p id="verifyOTPMsg" style={{ textAlign: 'center' }}>Your E-mail Verification code has been sent to your mail, please check and enter passcode: </p>

                  <div className="otpInputs">
                    <input type="number" onKeyUp={(e) => handleOtpChange(e)} />
                    <input type="number" onKeyUp={(e) => handleOtpChange(e)} />
                    <input type="number" onKeyUp={(e) => handleOtpChange(e)} />
                    <input type="number" onKeyUp={(e) => handleOtpChange(e)} />
                    <input type="number" onKeyUp={(e) => handleOtpChange(e)} />
                    <input type="number" onKeyUp={(e) => {
                      handleOtpChange(e);
                      if (e.key === "Enter") {
                        if (emailVerified === false)
                          verifySignUpOTP("email");
                        else
                          verifySignUpOTP("mobile");
                      }
                    }}
                    />
                  </div>

                  {/* Another input field to construct the concatenated OTP */}
                  <input type="text" id="concatenatedOtp" hidden />

                  <button onClick={() => {
                    if (emailVerified === false)
                      verifySignUpOTP("email");
                    else
                      verifySignUpOTP("mobile");
                  }}>Verify  {emailVerified === false ? "Email" : "Mobile"} OTP</button>
                  <p style={{ color: "gray", marginTop: 30 }}>Didn't Recieve code ? <a href='#' style={{ color: "#00BAFF" }} onClick={() => {
                    alert("Resending OTP");
                    // generateOTP();
                  }}>Request again</a></p>

                </div>
              </div> : ""
          }
        </Modal.Body>
      </Modal>

      {/* Verify OTP Model Definition */}
      {/* showOTP */}
      {/* <Modal show={showOTP} onHide={verifyOTPClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Verify with OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginLogo" onFocus={verifyOTPWin} >
            <div className="OTPmsg" name="OTPmsg" id="OTPmsg" >
              <label>One Time Password has been sent to your mobile : </label>
            </div>

            <form name="VerifyOTP" action="" method="post">
              <input className="OTPinput smallHeight" type="password" size="4" maxLength="6" name="otp2" id="otp2" autoFocus />
              <p id="timeslap"><span >01:30</span></p>
              <div><a className="smallHeight btn btn-outline-primary btn-sm" onClick={generateOTP}>resend OTP</a></div>
              <p id="verifyBut" className="smallHeight btn btn-primary btn-sm show" onClick={verifyOTP}>Verify</p>
              <p id="logButinVerifyWin" className="smallHeight btn btn-primary btn-sm hide" onClick={loginShow}>Login</p>
            </form>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal show={forgetPassModal} onHide={forgetPassModalClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id='forgot-pwd-model-title'>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"auto",paddingBottom:"30px"}}>
          <div className="loginLogo">
            <div className="OTPmsg" name="OTPmsg" id="OTPmsg" >
              <label style={{fontSize:"14px",color:"gray",alignSelf:"flex-start",display:"flex",marginLeft:"24px"}}>Please enter your registered mobile number</label>
            </div>

            <form name="VerifyOTP" action="" method="post">
              {!OTPVeri ?
                <>
                  {
                    isOTPVerifyShow == false ?
                      <>
                        <input style={{ width: "90%", padding: "15px", fontSize: "14px",marginBottom:"15px", borderRadius: "5px", borderStyle: "none", border: "1px solid #d1d5db" }} placeholder='Enter your registered mobile number' className="OTPinput smallHeight" type="text" size="8" maxLength="10" name="mobile" id="mobile" autoFocus />
                        <div style={{width:"90%",margin:"0px auto",border:"none"}}><a className="btnPrimary2" style={{ display:"flex", justifyContent: "center" ,color:"#fff" , padding: "10px 50px",width:"100%",border:"none"}} onClick={verify_valid_user}>Reset Password </a></div>
                        <p style={{textAlign:"left",marginLeft:"23px",marginTop:"20px",fontSize:"14px"}}>Don't have an account <Link style={{color:"#2FA3FF"}} onClick={()=>{
                          setShowSignUp(true);
                          setForgetPassModal(false);
                        }}>Sign Up</Link></p>
                      </> : ""
                  }
                  {isOTPVerifyShow ?
                    <>
                      <input placeholder='Enter OTP' style={{ width: "90%", padding: "15px", fontSize: "14px",marginBottom:"15px", borderRadius: "5px", borderStyle: "none", border: "1px solid #d1d5db" }} className="OTPinput smallHeight" type="text" size="4" maxLength="6" name="otp_rec" id="otp_rec" />
                      <p style={{width:"90%",margin:"0px auto",padding:"13px "}} id="verifyBut" className="smallHeight btnPrimary2 show" onClick={verify_RecOTP}>Verify</p>
                    </> : ""
                  }

                </>
                :
                <>
                  <input style={{ width: "90%", padding: "10px", fontSize: "14px",marginBottom:"15px", borderRadius: "5px", borderStyle: "none", border: "1px solid #d1d5db",marginTop:"10px" }} type='password' name="Npassword1" id="Npassword1" placeholder='Type new password' /> <br />
                  <input style={{ width: "90%", padding: "10px", fontSize: "14px",marginBottom:"15px", borderRadius: "5px", borderStyle: "none", border: "1px solid #d1d5db" }} type='password' name="Npassword2" id="Npassword2" placeholder='Retype new password' /> <br />
                  <p style={{fontSize:"14px",color:"gray",alignSelf:"flex-start",display:"flex",marginLeft:"30px"}}>Use atleast 8 characters</p>
                  <p style={{width:"90%",margin:"0px auto",padding:"13px "}} className="smallHeight btnPrimary2" onClick={resetPassword}>Change</p>
                </>
              }
            </form>
          </div>
        </Modal.Body>
      </Modal>




    </div>
  )
}

export default DigiPartNavBar
import React, { useEffect, useState } from 'react'
// import KeyboardEvent from "react";
import Button from '@material-ui/core/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import cart from '../../SharedItems';
import { ReactSession } from 'react-client-session';
import { v4 as uuidv4 } from 'uuid';

ReactSession.setStoreType("localStorage");

const NavSearchBar = (props) => {
  const navigate = useNavigate();
  const [rand, setRandom] = useState();
  const [productsData, setProductsData] = useState(null);
  const [clientIp, setClientIp] = useState('');
  const [searchText, setSearchText] = useState('');

  const fetchClientIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setClientIp(data.ip);
    } catch (error) {
      console.error('Error fetching client IP:', error);
    }
  };

  function clearSearch() {
    var ser = document.getElementById("search");
    if (ser !== null) {
      ser.value = '';
    }
    document.getElementById("cleanSearch")?.classList.replace("show", "hide");
    document.getElementById("searchProList")?.classList.replace("show", "hide");
  }

  function handleSearch(event){
    // console.log("Text :",event.target.value);
    setSearchText(event.target.value);
    populateSearch(event.target.value);
    if (event.key === "Enter") {
      navigate("/PillsSearch?sertext=" + document.getElementById("search").value);
      window.location.reload(true);
    }
  }

  function populateSearch(sertxt) {
    // var searchText = document.getElementById("search");
    var ProductContainer = document.getElementById("searchProList");
    document.getElementById("cleanSearch").classList.replace("hide", "show");
    if (sertxt.length > 2) {
      ProductContainer.classList.replace("hide", "show");
      var currentID = uuidv4();
      fetch("http://43.205.199.21/selectProduct?sertext=" + sertxt, {
        method: 'GET',
        // mode:'no-cors',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8', 'Client-ID': clientIp, 
          'X-Request-ID': currentID},
      }).then((response) => {
        return response.json();
      }).then((resp) => {
        setProductsData(resp.data);
      });
    }
    else {
      ProductContainer.classList.replace("show", "hide");
    }
  }

  const [idExist, verLogState] = useState(null);

  //Logout
  const Sheet360ERPLogout = () => {
    if (ReactSession.get("session")) {
      ReactSession.remove("session");
      ReactSession.remove("id_eu");
      ReactSession.remove("username_eu");
      ReactSession.remove("email_eu");
      ReactSession.remove("mobile_eu");
      navigate("/");
      verLogState(null);
      document.getElementById("CartCount").classList.replace("show", "hide");
      // window.location.reload(false);
    }
  }


  const removeErrorMessage = (element) => {
    var errEle = document.getElementById(element);
    if (errEle.classList.contains("errorField")) {
      errEle.classList.remove("errorField");
    }
  }

  function CheckLogin() {
    var errorElements = [];
    var loginID = document.getElementById("loginID");
    var pwd = document.getElementById("password");
    var apiURL = 'http://43.205.199.21/userAPI?loginID=' + loginID.value + '&password=' + pwd.value;

    // verLogState(JSON.parse(null));

    if (loginID.value === "")
      errorElements.push("loginID");

    if (pwd.value === "")
      errorElements.push("password");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      // return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");

      // First Method
      const xhr = new XMLHttpRequest();
      xhr.open('GET', apiURL);
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      xhr.onload = function () {
        if (xhr.status === 200) {
          cart.value = JSON.parse(xhr.responseText).cart;
          if (cart.value > 0) {
            document.getElementById("CartCount").classList.replace("hide", "show");
            document.getElementById("CartCount").innerText = cart.value;
          }

          ReactSession.set("session", true);
          ReactSession.set("id_eu", JSON.parse(xhr.responseText).id);
          ReactSession.set("username_eu", JSON.parse(xhr.responseText).name);
          ReactSession.set("email_eu", JSON.parse(xhr.responseText).email);
          ReactSession.set("mobile_eu", JSON.parse(xhr.responseText).mobile);
          loginClose();
        }
        else {
          document.getElementById("errorMessage").innerHTML = "Invalid Credentials........!";
          document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
          document.getElementById("loginID").focus();
          // return false;
        }
      };
      xhr.send();
    }
  }


  //LOGIN MODAL CONTROL METHODS
  const [showLog, setShow] = useState(false);
  const loginClose = () => setShow(false);
  const loginShow = () => {
    setShow(true);
    setShowSignUp(false);
    setShowVerifyOTP(false);
  }

  //SIGN UP MODAL CONTROL METHODS
  const [showSignup, setShowSignUp] = useState(false);
  const signupClose = () => setShowSignUp(false);
  const signupShow = () => {
    setShowSignUp(true);
    setShow(false)
  }

  const generateOTP = () => {
    var rnd = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    setRandom(rnd);
    var mobileNo = document.getElementById("mobile").value;
    sendOTP(rnd, mobileNo);
    // console.log(rnd);
  }

  //Verify OTP Window Modal controls
  const [showOTP, setShowVerifyOTP] = useState(false);
  const verifyOTPClose = () => setShowVerifyOTP(false);
  const verifyOTPShow = () => {
    setShowVerifyOTP(true);
    generateOTP();
  }


  // Verify OTP
  const verifyOTP = () => {
    var otp2 = parseInt(document.getElementById("otp2").value);
    if (rand === otp2) {
      registerUser();
      document.getElementById("OTPmsg").innerHTML = "Congrats, You are sucessfully registered, Login Now";
      document.getElementById("verifyBut").classList.replace("show", "hide");
      document.getElementById("logButinVer").classList.replace("hide", "show");
    }
    else
      document.getElementById("OTPmsg").innerHTML = "Incorrect OTP...!, Retry"
  }

  // React js Method for OTP SMS API call
  var data = null;
  const sendOTP = (otp, mobileNo) => {
    // [Quick text SMS API] "https://www.fast2sms.com/dev/bulkV2?authorization=kN1S64bWpOCJNCMePhXVifs9rKGrqVU3M0dNGC2rT4FjBMMfixOGNBS4ee1r&route=q&message=ApkeNumberperSMS_AYA_Hey&flash=0&numbers=9827749029"
    
    var url = "https://www.fast2sms.com/dev/bulkV2?authorization=kN1S64bWpOCJNCMePhXVifs9rKGrqVU3M0dNGC2rT4FjBMMfixOGNBS4ee1r&route=otp&sender_id=MMF&message=Sheet360ERP One Time Password &variables_values=" + otp + "&flash=0&numbers=" + mobileNo;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = function () {
      if (xhr.status === 200) {
        data = JSON.parse(xhr.responseText);
      }
    };
    xhr.send();
  }

  const verifyOTPWin = () => {
    var mobileNo = document.getElementById("mobile").value;
    document.getElementsByClassName("OTPmsg")[0].innerHTML = "<label>Please enter One Time Password sent on :" + mobileNo + "</label>";
  }

  // React js Method for register User API call
  const registerUser = () => {
    //43.205.199.21
    fetch('http://43.205.199.21/userAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        email: document.getElementById('eMail').value,
        mobile: document.getElementById('mobile').value,
        name: document.getElementById('fName').value + ' ' + document.getElementById('lName').value,
        gender: document.getElementById('gender').value,
        dob: document.getElementById('dob').value,
        password: document.getElementById('password1').value
      })
    }).then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  //Validation Sign Up form  
  const validatRegForm = () => {
    var errorElements = [];
    // verLogState(JSON.parse(null));

    var mailID = document.getElementById("eMail").value;
    var mobileNo = document.getElementById("mobile").value;
    var apiURL = 'http://43.205.199.21/varifylogIDAPI?email=' + mailID + '&mobile=' + mobileNo;

    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (document.getElementById("eMail").value === "")
        errorElements.push("eMail");
      else
        if (!document.getElementById("eMail").value.match(validRegex)) {
          errorElements.push("eMail");
          document.getElementById("errorMessage").innerHTML = "Please enter a vallid E-mail ID";
        }
        else
          if (resp.email > 0) {
            errorElements.push("eMail");
            document.getElementById("errorMessage").innerHTML = "E-mail ID already exist..!";
          }

      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain 10 digits";
        }
        else
          if (resp.mobile > 0) {
            errorElements.push("mobile");
            document.getElementById("errorMessage").innerHTML += "<br />Mobile number already registered..!";
          }

      if (document.getElementById("fName").value === "")
        errorElements.push("fName");
      if (document.getElementById("lName").value === "")
        errorElements.push("lName");
      if (document.getElementById("gender").value === "")
        errorElements.push("gender");
      if (document.getElementById("dob").value === "")
        errorElements.push("dob");
      if (document.getElementById("password1").value === "")
        errorElements.push("password1");
      else
        if (document.getElementById("password1").value.length < 8) {
          errorElements.push("password1");
          document.getElementById("errorMessage").innerHTML += "<br />Password must contains 8 characters";
        }

      if (document.getElementById("password2").value === "")
        errorElements.push("password2");
      if (document.getElementById("password1").value !== document.getElementById("password2").value) {
        errorElements.push("password2");
        document.getElementById("errorMessage").innerHTML += "<br />Confirm Password Doesn't match";
      }

      errorElements.forEach(showError);
      function showError(item) {
        document.getElementById(item).classList.add("errorField");
      }

      if (errorElements.length > 0) {
        document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
        document.getElementById(errorElements[0]).focus();
        return false;
      }
      else {
        document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
        verifyOTPShow();
      }
    });
  }

  // Sub navigation control Scripts
  const ToggleSubMenu = () => {
    document.getElementById("SubNav").classList.toggle("show");
  }

  // Close the dropdown if the user clicks outside of it
  window.onclick = function (e) {
    if (!e.target.matches('.subBut')) {
      var myDropdown = document.getElementById("SubNav");
      clearSearch();
      if (myDropdown?.classList.contains('show')) {
        myDropdown.classList.remove('show');
      }
    }
  }

  useEffect(()=>{
    // fetchClientIp();
    setClientIp(uuidv4());
  }, []);

  return (
    <nav className="navBar sticky-top" id="NavBar">
      <div className="subnav">
        <img className="subBut" onClick={() => { ToggleSubMenu() }} src={require("../../static/images/Icons/Menu.png")} alt="" />
        <div className='subNavItems' id="SubNav">
          {
            ReactSession.get("session") ?
              <a href={() => false}>
                <img className="SubLogo" src={require("../../static/images/SubNav/User.png")} alt="" /> &nbsp;&nbsp;&nbsp;
                {ReactSession.get("username_eu")} <br />
                {ReactSession.get("mobile_eu")}<br />
                {ReactSession.get("email_eu")}
              </a> :
              <span>
                <img className="SubLogo" src={require("../../static/images/SubNav/User.png")} alt="" /> &nbsp;&nbsp;&nbsp;
                Hi There...<a href={() => false} onClick={loginShow}><img className="SubLogo" src={require("../../static/images/SubNav/login.png")} alt="" />&nbsp;&nbsp;&nbsp;Login</a>
              </span>
          }
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/wallet.png")} alt="" />&nbsp;&nbsp;&nbsp;My Wallet</a>
          <a onClick={function (e) { navigate('/UploadPrescription') }}><img className="SubLogo" src={require("../../static/images/SubNav/Upload.png")} alt="" />&nbsp;&nbsp;&nbsp;Upload Prescription</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/members.png")} alt="" />&nbsp;&nbsp;&nbsp;Membership Plan</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/Offers.png")} alt="" />&nbsp;&nbsp;&nbsp;Offers</a>
          <a onClick={function (e) { navigate('/MyOrders') }}><img className="SubLogo" src={require("../../static/images/SubNav/Orders.png")} alt="" />&nbsp;&nbsp;&nbsp;My Orders</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/refer.png")} alt="" />&nbsp;&nbsp;&nbsp;Refer & Earn</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/Chat.png")} alt="" />&nbsp;&nbsp;&nbsp;Chat with Us</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/request.png")} alt="" />&nbsp;&nbsp;&nbsp;Request For Product</a>
          <a onClick={function (e) { navigate('/DigitalPartner') }}><img className="SubLogo" src={require("../../static/images/SubNav/partner.png")} alt="" />&nbsp;&nbsp;&nbsp;Digital Partner</a>
          <a onClick={function (e) { navigate('/Admin') }}><img className="SubLogo" src={require("../../static/images/SubNav/admin.png")} alt="" />&nbsp;&nbsp;&nbsp;Admin</a>
          <a href={() => false}><img className="SubLogo" src={require("../../static/images/SubNav/Aboutus.png")} alt="" />&nbsp;&nbsp;&nbsp;About Us</a>
          <a onClick={() => { Sheet360ERPLogout() }}><img className="SubLogo" src={require("../../static/images/SubNav/Logout.png")} alt="" />&nbsp;&nbsp;&nbsp;Logout</a>
        </div>
      </div>


      <div className="navItem search" >
        <input type="text" placeholder="Search......." name="search" autoComplete="off" id="search" style={{ width: '100%' }} value={searchText}
          onChange={handleSearch}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              navigate("/PillsSearch?sertext=" + searchText);
              window.location.reload(true);
            }
          }}
        />
        <div className="cleanSearch hide" id="cleanSearch" onClick={clearSearch}><img src={require("../../static/images/Icons/close.png")} alt="" /></div>

        <div className="searchProList divshadow hide" id="searchProList">
          {
            productsData ?
              productsData.map((product, index) =>
                <div key={index} className='searchResModal topPartition' onClick={() => {
                  navigate("/DisplayProduct?pro_id=" + product[0])
                  window.location.reload(true);
                }}>
                  <div className='row'>
                    <div className='col-9' style={{ fontSize: '1rem' }} >{product[1]}</div>
                    <div className='col-2' style={{ fontSize: '1rem' }} >Price: {product[5]}</div>
                  </div>
                  <div className='row'>
                    <div className='col-9' style={{ fontSize: '1rem' }} >{product[2]}</div>
                    <div className='col-2' style={{ fontSize: '1rem' }} >MRP :
                      <span style={{ textDecoration: 'line-through' }}>{Number(product[4]).toFixed(2)} </span>
                    </div>
                  </div>
                </div>
              )
              : ''
          }
        </div>
        <button style={{backgroundColor:"black"}} type="submit"><i className="fa fa-search"></i></button>
      </div>

      <Link to='/UploadPrescription'>
        <img className="navItem" src={require("../../static/images/Icons/Upload.png")} alt="" />
        <p className='navItem'>UPLOAD<br />PRESCRIPTION</p>
      </Link>
      <img className="navItem" src={require("../../static/images/Icons/Contact.png")} alt="" />
      <img className="navItem" src={require("../../static/images/Icons/Notifications.png")} alt="" />
      <img className="navItem" src={require("../../static/images/Icons/Offers.png")} alt="" />
      {
        ReactSession.get("session") ?
          <a onClick={Sheet360ERPLogout} ><img className="navItem logButtons" style={{ height: '2.5rem', marginTop: "-.20rem" }} src={require("../../static/images/Icons/exit.png")} alt="" /></a>
          :
          <img className="navItem logButtons" onClick={loginShow} src={require("../../static/images/Icons/Login1.png")} alt="" />
      }

      <img className="navItem logButtons" onClick={signupShow} src={require("../../static/images/Icons/SignUp.png")} alt="" />

      <Button variant="text" style={{ float: 'right' }} onClick={function (e) { navigate('/MyCart') }}>
        <img className="navItem" src={require("../../static/images/Icons/Cart1.png")} style={{ float: 'right' }} alt="" />
        <span className='CartCount hide' id='CartCount'></span>
      </Button>

      {/* Login Model Definition */}
      <Modal show={showLog} onHide={loginClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>User Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6 col-sm-12 loginLogo">
              <img className="logoimage" src={require("../../static/images/Pillsit_Logo.png")} alt="" />
              <div className="socNetLogin">
                <p><br /><br /><br /><br /><br />Follow us:</p>
                <img className="socImg1" src={require('../../static/images/SocialNetworks/facebook.png')} alt="..." /> &nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg2" src={require('../../static/images/SocialNetworks/instagram.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg3" src={require('../../static/images/SocialNetworks/linkedin.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg4" src={require('../../static/images/SocialNetworks/twitter.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg5" src={require('../../static/images/SocialNetworks/youtube.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-12 LeftBorder">
              <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
              <br />
              <input className="LoginInput" name="loginID" id="loginID" type="text" onKeyPress={() => { removeErrorMessage("loginID") }} placeholder="Registered E-mail/Contact number" autoFocus />
              <input className="LoginInput" name="password" id="password" type="password" onKeyPress={() => { removeErrorMessage("password") }} placeholder="Password...." />
              <div className="loginLogo">
                <button className="btn btn-primary btn-sm smallHeight" onClick={CheckLogin}>Sign In</button>
                <br />
                <br />
              </div>
              <div className="loginLogoCred">
                <p>Don't have an account ? &nbsp;&nbsp;&nbsp;<p className="signInSignUpBtn" onClick={signupShow}>Sign Up</p></p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SIGN UP MODAL */}
      <Modal show={showSignup} onHide={signupClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-4 col-sm-12 loginLogo">
              <img className="logoimage" src={require("../../static/images/Pillsit_Logo.png")} alt="" />
              <div className="socNetSignUp">
                <p style={{ fontSize: '.75rem' }}><br /><br /><br /><br /><br /><br /><br />Follow us:</p>
                <img className="socImg1" src={require('../../static/images/SocialNetworks/facebook.png')} alt="..." /> &nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg2" src={require('../../static/images/SocialNetworks/instagram.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg3" src={require('../../static/images/SocialNetworks/linkedin.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg4" src={require('../../static/images/SocialNetworks/twitter.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
                <img className="socImg5" src={require('../../static/images/SocialNetworks/youtube.png')} alt="..." />&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div className="col-12 col-md-1 col-sm-12">
            </div>

            <div className="col-12 col-md-7 col-lg-7 col-sm-12 LeftBorder">
              <form name="userReg" action="" method="post" style={{ marginLeft: '15%' }}>
                <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
                <tr>
                  <td><input className="formInput" name="eMail" id="eMail" type="email" onKeyPress={() => { removeErrorMessage("eMail") }} placeholder="E-mail...." autoFocus /></td>
                </tr>
                <tr >
                  <td>
                    <select style={{ float: "left" }} className="formInput coCode" name="countryCode" id="countryCode">
                      <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                      <option data-countryCode="AD" value="376">Andorra (+376)</option>
                      <option data-countryCode="AO" value="244">Angola (+244)</option>
                      <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                      <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                      <option data-countryCode="AR" value="54">Argentina (+54)</option>
                      <option data-countryCode="AM" value="374">Armenia (+374)</option>
                      <option data-countryCode="AW" value="297">Aruba (+297)</option>
                      <option data-countryCode="AU" value="61">Australia (+61)</option>
                      <option data-countryCode="AT" value="43">Austria (+43)</option>
                      <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                      <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                      <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                      <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                      <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                      <option data-countryCode="BY" value="375">Belarus (+375)</option>
                      <option data-countryCode="BE" value="32">Belgium (+32)</option>
                      <option data-countryCode="BZ" value="501">Belize (+501)</option>
                      <option data-countryCode="BJ" value="229">Benin (+229)</option>
                      <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                      <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                      <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                      <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                      <option data-countryCode="BW" value="267">Botswana (+267)</option>
                      <option data-countryCode="BR" value="55">Brazil (+55)</option>
                      <option data-countryCode="BN" value="673">Brunei (+673)</option>
                      <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                      <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                      <option data-countryCode="BI" value="257">Burundi (+257)</option>
                      <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                      <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                      <option data-countryCode="CA" value="1">Canada (+1)</option>
                      <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                      <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                      <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                      <option data-countryCode="CL" value="56">Chile (+56)</option>
                      <option data-countryCode="CN" value="86">China (+86)</option>
                      <option data-countryCode="CO" value="57">Colombia (+57)</option>
                      <option data-countryCode="KM" value="269">Comoros (+269)</option>
                      <option data-countryCode="CG" value="242">Congo (+242)</option>
                      <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                      <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                      <option data-countryCode="HR" value="385">Croatia (+385)</option>
                      <option data-countryCode="CU" value="53">Cuba (+53)</option>
                      <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                      <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                      <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                      <option data-countryCode="DK" value="45">Denmark (+45)</option>
                      <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                      <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                      <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                      <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                      <option data-countryCode="EG" value="20">Egypt (+20)</option>
                      <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                      <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                      <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                      <option data-countryCode="EE" value="372">Estonia (+372)</option>
                      <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                      <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                      <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                      <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                      <option data-countryCode="FI" value="358">Finland (+358)</option>
                      <option data-countryCode="FR" value="33">France (+33)</option>
                      <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                      <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                      <option data-countryCode="GA" value="241">Gabon (+241)</option>
                      <option data-countryCode="GM" value="220">Gambia (+220)</option>
                      <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                      <option data-countryCode="DE" value="49">Germany (+49)</option>
                      <option data-countryCode="GH" value="233">Ghana (+233)</option>
                      <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                      <option data-countryCode="GR" value="30">Greece (+30)</option>
                      <option data-countryCode="GL" value="299">Greenland (+299)</option>
                      <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                      <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                      <option data-countryCode="GU" value="671">Guam (+671)</option>
                      <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                      <option data-countryCode="GN" value="224">Guinea (+224)</option>
                      <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                      <option data-countryCode="GY" value="592">Guyana (+592)</option>
                      <option data-countryCode="HT" value="509">Haiti (+509)</option>
                      <option data-countryCode="HN" value="504">Honduras (+504)</option>
                      <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                      <option data-countryCode="HU" value="36">Hungary (+36)</option>
                      <option data-countryCode="IS" value="354">Iceland (+354)</option>
                      <option data-countryCode="IN" value="91" selected >India (+91)</option>
                      <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                      <option data-countryCode="IR" value="98">Iran (+98)</option>
                      <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                      <option data-countryCode="IE" value="353">Ireland (+353)</option>
                      <option data-countryCode="IL" value="972">Israel (+972)</option>
                      <option data-countryCode="IT" value="39">Italy (+39)</option>
                      <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                      <option data-countryCode="JP" value="81">Japan (+81)</option>
                      <option data-countryCode="JO" value="962">Jordan (+962)</option>
                      <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                      <option data-countryCode="KE" value="254">Kenya (+254)</option>
                      <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                      <option data-countryCode="KP" value="850">Korea North (+850)</option>
                      <option data-countryCode="KR" value="82">Korea South (+82)</option>
                      <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                      <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                      <option data-countryCode="LA" value="856">Laos (+856)</option>
                      <option data-countryCode="LV" value="371">Latvia (+371)</option>
                      <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                      <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                      <option data-countryCode="LR" value="231">Liberia (+231)</option>
                      <option data-countryCode="LY" value="218">Libya (+218)</option>
                      <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                      <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                      <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                      <option data-countryCode="MO" value="853">Macao (+853)</option>
                      <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                      <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                      <option data-countryCode="MW" value="265">Malawi (+265)</option>
                      <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                      <option data-countryCode="MV" value="960">Maldives (+960)</option>
                      <option data-countryCode="ML" value="223">Mali (+223)</option>
                      <option data-countryCode="MT" value="356">Malta (+356)</option>
                      <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                      <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                      <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                      <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                      <option data-countryCode="MX" value="52">Mexico (+52)</option>
                      <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                      <option data-countryCode="MD" value="373">Moldova (+373)</option>
                      <option data-countryCode="MC" value="377">Monaco (+377)</option>
                      <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                      <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                      <option data-countryCode="MA" value="212">Morocco (+212)</option>
                      <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                      <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                      <option data-countryCode="NA" value="264">Namibia (+264)</option>
                      <option data-countryCode="NR" value="674">Nauru (+674)</option>
                      <option data-countryCode="NP" value="977">Nepal (+977)</option>
                      <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                      <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                      <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                      <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                      <option data-countryCode="NE" value="227">Niger (+227)</option>
                      <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                      <option data-countryCode="NU" value="683">Niue (+683)</option>
                      <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                      <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                      <option data-countryCode="NO" value="47">Norway (+47)</option>
                      <option data-countryCode="OM" value="968">Oman (+968)</option>
                      <option data-countryCode="PW" value="680">Palau (+680)</option>
                      <option data-countryCode="PA" value="507">Panama (+507)</option>
                      <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                      <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                      <option data-countryCode="PE" value="51">Peru (+51)</option>
                      <option data-countryCode="PH" value="63">Philippines (+63)</option>
                      <option data-countryCode="PL" value="48">Poland (+48)</option>
                      <option data-countryCode="PT" value="351">Portugal (+351)</option>
                      <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                      <option data-countryCode="QA" value="974">Qatar (+974)</option>
                      <option data-countryCode="RE" value="262">Reunion (+262)</option>
                      <option data-countryCode="RO" value="40">Romania (+40)</option>
                      <option data-countryCode="RU" value="7">Russia (+7)</option>
                      <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                      <option data-countryCode="SM" value="378">San Marino (+378)</option>
                      <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                      <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                      <option data-countryCode="SN" value="221">Senegal (+221)</option>
                      <option data-countryCode="CS" value="381">Serbia (+381)</option>
                      <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                      <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                      <option data-countryCode="SG" value="65">Singapore (+65)</option>
                      <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                      <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                      <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                      <option data-countryCode="SO" value="252">Somalia (+252)</option>
                      <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                      <option data-countryCode="ES" value="34">Spain (+34)</option>
                      <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                      <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                      <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                      <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                      <option data-countryCode="SD" value="249">Sudan (+249)</option>
                      <option data-countryCode="SR" value="597">Suriname (+597)</option>
                      <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                      <option data-countryCode="SE" value="46">Sweden (+46)</option>
                      <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                      <option data-countryCode="SI" value="963">Syria (+963)</option>
                      <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                      <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                      <option data-countryCode="TH" value="66">Thailand (+66)</option>
                      <option data-countryCode="TG" value="228">Togo (+228)</option>
                      <option data-countryCode="TO" value="676">Tonga (+676)</option>
                      <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                      <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                      <option data-countryCode="TR" value="90">Turkey (+90)</option>
                      <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                      <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                      <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                      <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                      <option data-countryCode="UG" value="256">Uganda (+256)</option>
                      <option data-countryCode="GB" value="44">UK (+44)</option>
                      <option data-countryCode="UA" value="380">Ukraine (+38)</option>
                      <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                      <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                      <option data-countryCode="US" value="1">USA (+1)</option>
                      <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                      <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                      <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                      <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                      <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                      <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                      <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                      <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                      <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                      <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                      <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                      <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                    </select> &nbsp;&nbsp;&nbsp;
                    <input style={{ float: "left" }} className="formInput mobileN" name="mobile" id="mobile" type="text" size="4" maxLength="10" onKeyPress={() => { removeErrorMessage("mobile") }} placeholder="Mobile number...." />
                  </td>
                </tr>
                <tr >
                  <td ><input className="formInput" name="fName" id="fName" type="text" onKeyPress={() => { removeErrorMessage("fName") }} placeholder="First/Middle Name...." /></td>
                </tr>
                <tr>
                  <td><input className="formInput" name="lName" id="lName" type="text" onKeyPress={() => { removeErrorMessage("lName") }} placeholder="Last Name...." /></td>
                </tr>
                <tr >
                  <td style={{ display: "flex" }}>
                    <label style={{ float: "left" }} className='formInputCol'>Gender :&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <input style={{ float: "left" }} className='form-check-input' type="radio" value="male" name="gender" id="gender" />
                    <label style={{ float: "left" }} className='formInputCol'>Male&nbsp;&nbsp;</label>
                    <input style={{ float: "left" }} className='form-check-input' type="radio" value="female" name="gender" />
                    <label style={{ float: "left" }} className='formInputCol'>Female&nbsp;&nbsp;</label>
                    <input style={{ float: "left" }} className='form-check-input' type="radio" value="others" name="gender" />
                    <label style={{ float: "left" }} className='formInputCol'>Others</label>
                  </td>
                </tr>
                <tr >
                  <td>
                    <label style={{ float: "left" }} className='formInputCol'>Date of Birth &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <input style={{ float: "left", width: "50%" }} className="formInput" name="dob" id="dob" type="date" onChange={() => { removeErrorMessage("dob") }} />
                  </td>
                </tr>

                <tr>
                  <td ><input className="formInput" name="password1" id="password1" type="password" onKeyPress={() => { removeErrorMessage("password1") }} placeholder="Password...." /></td>
                </tr>
                <tr>
                  <td ><input className="formInput" name="password2" id="password2" type="password" onKeyPress={() => { removeErrorMessage("password2") }} placeholder="Confirm Password...." /></td>
                </tr>
              </form>
              <p></p>
              <div className="loginLogo">
                <button className="smallHeight btn btn-primary btn-sm" onClick={validatRegForm}>Register</button>
                <p className='formInputCol' style={{ fontSize: '.65rem' }}>By signing up, you agree to our Terms and Conditions & Privacy Policy</p>
              </div>
              <div className="loginLogoCred">
                <p className='formInputCol' style={{ fontSize: '.75rem' }}>Have an account ? &nbsp;&nbsp;&nbsp;<p className="signInSignUpBtn" onClick={loginShow}>Login</p></p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Verify OTP Modal Definition */}
      <Modal show={showOTP} onHide={verifyOTPClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Verify with OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginLogo" onFocus={verifyOTPWin} >
            <div className="OTPmsg" name="OTPmsg" id="OTPmsg" >
              <label>One Time Password has been sent to your mobile : </label>
            </div>

            <form name="VerifyOTP" action="" method="post">
              <input className="OTPinput smallHeight" type="password" size="4" maxLength="6" name="otp2" id="otp2" autoFocus />
              {/* <p id="timeslap"><span >01:30</span></p> */}
              <div><a className="smallHeight btn btn-outline-primary btn-sm" onClick={generateOTP}>resend OTP</a></div>
              <p id="verifyBut" className="smallHeight btn btn-primary btn-sm show" onClick={verifyOTP}>Verify</p>
              <p id="logButinVer" className="smallHeight btn btn-primary btn-sm hide" onClick={loginShow}>Login</p>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </nav>
  )
}

export default NavSearchBar
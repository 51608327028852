import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import { useReactToPrint } from 'react-to-print';

import '../../stockPrint.css';
import Loader from '../Containers/Loader';

ReactSession.setStoreType("localStorage");


const DPSalesStockState = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [stockData, setStockData] = useState(null);
  const [DPProfile, setDPProfile] = useState(null);

  const [filltered, setFilltered] = useState(false);

  // Handle Printer
  const printUserStockRef = React.useRef();
  const handlePrintUserStock = useReactToPrint({
    content: () => printUserStockRef.current,
  });

  // const [col1, setCol1] = useState(0);
  // const [col2, setCol2] = useState(0);
  // const [col3, setCol3] = useState(0);
  // const [col4, setCol4] = useState(0);
  // const [col5, setCol5] = useState(0);
  var col1 = 0;
  var col2 = 0;
  var col3 = 0;
  var col4 = 0;
  var col11 = 0;
  var col22 = 0;
  var col33 = 0;
  var col44 = 0;

  const executeQry = () => {
    setFilltered(true);
    var dtFrom = document.getElementById("dateFrom");
    var dtTo = document.getElementById("dateTo");
    if (String(dtFrom.value).length < 8) {
      alert("Please enter a valid date :");
      dtFrom.focus();
    }
    else
      if (String(dtTo.value).length < 8) {
        alert("Please enter a valid date :");
        dtTo.focus();
      }
      else {
        // console.log("Executing query");
        var st_date = "" + dtFrom.value + "' and u.created_at <= '" + dtTo.value + "'";
        getStockData(dtFrom.value, dtTo.value);
      }
  }

  const getStockData = (dtFrom = null, dtTo = null) => {
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?stockState=1&user_Id=' + ReactSession.get('id')
    if (dtFrom != null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else if (resp.status === "accessError")
      {
        alert("Your current subscription doesnot support Sales & Stock Statement service, please contact Sheet360ERP sales team");
      }
      else if (resp.dp_profile.length > 0)
      {
        setStockData(resp.stockData);
        setDPProfile(resp.dp_profile[0]);
      }
      setLoader(false);
    });
  }


  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) =>{
    if (value)
      {
        document.getElementById('DigiDashPanel').style.display = 'block';
        document.getElementById('dpSidePanelBTN').style.left='75vw';
      }
    else
    {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left='0px';
    }
    setMenuCollapse(value);
  }
  
  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before Sales & Stock Statements...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          setFilltered(false);
          getStockData();
        }
  }, []);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
  
          {loader ? <Loader /> : null}

          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={()=>CollapseMenu(!menuCollapse)}>
            { menuCollapse ? 
            <img src={require('../../static/images/Icons/left-arrow.png')} alt=''/>
            :
            <img src={require('../../static/images/Icons/right-arrow.png')} alt=''/>
            }
          </div>
        
          {/* <div style={{margin:'.5%', width:'100%', fontSize:'120%', textAlign:'center', borderBottom: '3px solid rgb(61, 197, 238)'}}>Sales & Stock Statement</div> */}
          <div style={{ margin: '.5%', width: '98%', fontSize: '120%', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBlock: '.5vw' }}>
              <div style={{ width: '100%' }} >
                <p style={{fontSize: "31px",margin:0,padding:0}}>Sales & Stock Statement</p>
              </div>
              {/* <div style={{width:'65%', marginLeft:'10%'}}> <input style={{width:'110%'}} type='text' name='searchTxt' id='searchTxt' onChange={filterUsersData} className='form-control form-control-sm' placeholder='Search by Name/Email/Mobile :'/></div> */}
            </div>

            <div style={{ display: 'flex', fontSize: '75%', alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* <div>&nbsp;&nbsp;Date From&nbsp;&nbsp;</div> */}
              <div><input type='date' name='dateFrom' id='dateFrom' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
              <div>&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</div>
              <div><input type='date' name='dateTo' id='dateTo' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
              <div>&nbsp;&nbsp;&nbsp;</div>
              <div className='digiNavBtn2 mediumButtons' style={{borderRadius:"5px"}} onClick={() => executeQry()}>Go</div>
              <div className='digiNavBtn2 mediumButtons' style={{borderRadius:"5px"}} onClick={handlePrintUserStock}>Print</div>
            </div>
          </div>

          <div style={{ marginInline: '.5%', width: '99%', display: 'flex', justifyContent: 'flex-start',background:"#D3D3D3",color:"#000",alignItems:"center",marginBottom:10,marginTop:-10 }}>
            <div style={{ width: '7%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>S.NO</div>
            <div style={{ width: '30%', padding: '.5%', fontSize: '90%', fontWeight: 'bold' }}>Item Name</div>
            {/* <div style={{ width: '6%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>MRP</div>
            <div style={{ width: '8%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Expiry</div> */}
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Opening Stock</div>
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Received</div>
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Issued</div>
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Closing Stock</div>
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Stock Value</div>
            <div style={{ width: '9%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Frequency</div>
            <div style={{ width: '7%', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Rack</div>
            <div style={{ width: '.5rem', borderRight: 'none', borderBlock: 'none' }}></div>
          </div>
          <div className='stockDataPanel customScroll'>
            {
              stockData ?
                stockData.map((item, index) => {
                  {/* console.log("Rec :", item); */}
                  {/* setCol1(col1 + item[13]); */ }
                  var op_st = filltered ? Number(item[5]) + Number(item[6]) + Number(item[7]) - Number(item[8]) : Number(item[5]);
                  var st_recv = filltered ? Number(item[16]) + Number(item[17]) : Number(item[6]);
                  var st_iss = filltered ? Number(item[18]) : Number(item[7]);
                  {/* var st_clos = filltered ? (Number(item[5]) + Number(item[6]) + Number(item[7]) - Number(item[8])) + (Number(item[16]) + Number(item[17]) - Number(item[18])) : item[8];
                  var st_val = filltered ? Number(((Number(item[5]) + Number(item[6]) + Number(item[7]) - Number(item[8])) + (Number(item[16]) + Number(item[17]) - Number(item[18]))) * Number(item[13] === 0 ? item[19] : item[13])).toFixed(2) : Number(item[13]).toFixed(2); */}

                  var st_clos = filltered ? Number(op_st) + Number(st_recv) - Number(st_iss) : Number(item[8]);
                  var st_val = filltered ? Number(Number(st_clos) * (Number(item[13]) === 0 ? Number(item[19]) : Number(item[13]))).toFixed(2) : Number(item[13]).toFixed(2);
                  
                  {/* if (st_recv !== null && st_recv !== 'null')
                    col1 = col1 + (Number(st_recv) * (Number(item[10]) === 0 ? Number(item[19]) : Number(item[10])));

                  if (st_iss !== null && st_iss !== 'null')
                    col2 = col2 + (Number(st_iss) * (Number(item[10]) === 0 ? Number(item[19]) : Number(item[10])));

                  if (st_clos !== null && st_clos !== 'null')
                    col3 = col3 +  (Number(st_clos) * (Number(item[10]) === 0 ? Number(item[19]) : Number(item[10])));
                  
                  col4 += Number(st_val); */}

                  col1 += parseFloat(item[19]);
                  col2 += parseFloat(item[20]);
                  col3 += (parseFloat(item[19]) - parseFloat(item[20]));
                  col4 += parseFloat(st_val);
                  
                  
                  {/* console.log("Rec :",col1, "  Issu :",col2,"qty:",st_iss, "   Closing:",col3,"  stclos:",st_clos, "Val :",col4); */}

                  return (
                    <div className='grayHover' key = {index} style={{ marginInline: '.5%', width: '99%', display: 'flex', backgroundColor:'white', justifyContent: 'flex-start',cursor:"pointer",padding:7,alignItems:"center" }}>
                    {/*  Fields for Full stock statements without query
                    id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, received6, issued7, stock_qty8, reserve_stock9, pro_mrp10, 
                    exp_month11, exp_year12, stockVal13, frequency14, batch_no15, rack_no16 */}

                    {/* Fields for stock statements with query
                      id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, p_qty6, f_qty7, s_qty8, ''9, pro_mrp10, exp_month11, exp_year12, 
                      dist_price13, freq14, batch_no15, p_qty16, f_qty17, s_qty18, sp_dist_price19, sp_exp_month20, sp_exp_year21, rack_no22 
                      */}

                      <div style={{ width: '7%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{index + 1}</div>
                      <div style={{ width: '30%', paddingInline: '.5%', fontSize: '90%' }}>
                        <div>{item[3]}</div>
                        <div style={{ fontSize: '80%' }}>{item[4]}</div>
                      </div>
                      {/* <div style={{ width: '6%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{Number(item[10]).toFixed(2)}</div>
                      <div style={{ width: '8%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[11]}/{item[12]}</div> */}
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{op_st}</div>
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_recv}</div>
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_iss}</div>
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_clos}</div>
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center',color: Number(st_val) < 0 ? 'red' : '' }}>{Number(st_val).toFixed(2)}</div>
                      <div style={{ width: '9%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[14]}</div>
                      <div style={{ width: '7%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}> {item[16]} </div>
                    </div>
                  )
                }
                )
                :
                null
            }
          </div>

          <div style={{ fontSize:'1.1vw', marginInline: '.5%', width: '99%', display: 'flex', justifyContent: 'flex-start',background:"#D3D3D3" }}>
            <div style={{ width: '7%', paddingTop: '.5%', paddingBottom: '.5%', }}></div>
            <div style={{ width: '30%', paddingTop: '.5%', paddingBottom: '.5%', fontWeight: 'bold' }}>Totals</div>
            {/* <div style={{ width: '6%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}></div>
            <div style={{ width: '8%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}></div> */}
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}></div>
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}>{Math.round(col1)}</div>
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}>{Math.round(col2)}</div>
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}>{Math.round(col3)}</div>
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}>{Math.round(col4)}</div>
            <div style={{ width: '9%', paddingTop: '.5%', paddingBottom: '.5%', textAlign: 'center',fontWeight: 'bold' }}></div>
            <div style={{ width: '7%', paddingTop: '.5%', paddingBottom: '.5%', }}></div>
            <div style={{ width: '.5rem', borderRight: 'none', borderBlock: 'none' }}></div>
          </div>

          {/* <div style={{ margin: '.5%', padding: '1%', marginRight: '5%', width: '99%', fontSize: '120%', display: 'flex', justifyContent: 'flex-end', borderBottom: '3px solid rgb(61, 197, 238)' }}>
            <div className='btnPrimary'>Print</div>
          </div> */}
        </div>

      </div>

      {/* Print Component */}
      <div ref={printUserStockRef} className='printable-document' >
        {
          stockData ?
            stockData.map((item, index) => {       
              {/*  Fields for Full stock statements without query
                id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, received6, issued7, stock_qty8, reserve_stock9, pro_mrp10, 
              exp_month11, exp_year12, stockVal13, frequency14, batch_no15, rack_no16 */}
              
              {/* Fields for stock statements with query
              id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, p_qty6, f_qty7, s_qty8, ''9, pro_mrp10, exp_month11, exp_year12, 
              dist_price13, freq14, batch_no15, p_qty16, f_qty17, s_qty18, sp_dist_price19, sp_exp_month20, sp_exp_year21, rack_no22 
              */}

              var op_st = filltered ? Number(item[5]) + Number(item[6]) + Number(item[7]) - Number(item[8]) : item[5];
              var st_recv = filltered ? Number(item[16]) + Number(item[17]) : item[6];
              var st_iss = filltered ? Number(item[18]) : item[7];
              var st_clos = filltered ? Number(op_st) + Number(st_recv) - Number(st_iss) : Number(item[8]);
              var st_val = filltered ? Number(Number(st_clos) * (Number(item[13]) === 0 ? Number(item[19]) : Number(item[13]))).toFixed(2) : Number(item[13]).toFixed(2);
              
              {/* if (st_recv !== null && st_recv !== 'null')
                col11 = col11 + (Number(st_recv) * Number(item[13]) === 0 ? Number(item[19]) : Number(item[13]));

              if (st_iss !== null && st_iss !== 'null')
                col22 = col22 + (Number(st_iss) * Number(item[13]) === 0 ? Number(item[19]) : Number(item[13]));

              if (st_clos !== null && st_clos !== 'null')
                col33 = col33 +  (Number(st_clos) * Number(item[13]) === 0 ? Number(item[19]) : Number(item[13])); */}

              col11 += parseFloat(item[19]);
              col22 += parseFloat(item[20]);
              col33 += (parseFloat(item[19]) - parseFloat(item[20]));
              col44 += parseFloat(st_val);

              return (
                <>
                { index % 20 === 0 && index > 9 ?
                  <div style={{ marginLeft: '30%' , fontStyle: "italic", textAlign: "right"}}>to be continued on next page......</div>
                  : null
                }

                {/* vendor_id0, user_id1, business_Name2, business_address3, business_address2_4, ct.CityName5, st.StateName6, stdCode7, landline_number8,
                  landline_number3_9, landline_number3_10, CTX1_11, CTX2_12, CTX3_13, business_mobile14, gst_number15 */}
                {
                index % 20 === 0 ?
                  <div className='page-break' style={{ width: '100%', marginInline: '.5%', marginTop:'6%' }}>
                    { index ===0 ?
                      DPProfile !== null ?
                      <div style={{display:'block', textAlign:'center'}}>
                        <div style={{fontSize:'150%', lineHeight:'95%',fontWeight:"500"}}>{DPProfile[2]}</div>
                        <div style={{fontSize:'90%', color:"gray"}}>{DPProfile[3]}, {DPProfile[4]}, {DPProfile[5]}, {DPProfile[6]}</div>
                        <div style={{fontSize:'90%', color:"gray"}}>Phone : {DPProfile[7]}-{DPProfile[8]} ,{DPProfile[9]}, {DPProfile[10]} | CTX : {DPProfile[11]}, {DPProfile[12]}, {DPProfile[12]} | Mobile : {DPProfile[14]}</div>
                        <div style={{fontSize:'90%',color:"gray"}}>GSTIN : {DPProfile[15]}</div>
                        <div>Sales & Stock Statement {String(document.getElementById("dateFrom").value).length < 8 || String(document.getElementById("dateTo").value).length < 8 ? '' : "for the period of :"+document.getElementById("dateFrom").value+" to "+document.getElementById("dateTo").value }</div>
                      </div>
                      : null
                    : null}
                    
                    <div style={{ borderBlock: '1px solid gray', width: '99%', display: 'flex',alignItems:"center" ,justifyContent: 'flex-start' }}>
                      <div style={{ width: '40%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold' }}>Item Description</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Expiry</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Opening</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Received</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Issued</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Closing</div>
                      <div style={{ width: '10%', borderRight: '1px solid gray', padding: '.5%', fontSize: '90%', fontWeight: 'bold', textAlign: 'center' }}>Closing Value</div>
                    </div> 
                  </div>
                :
                null}

                  <div style={{ marginInline: '.5%', width: '99%', display: 'flex', alignItems:"center",padding:"5px",backgroundColor: index % 2 === 0 ? '#f3f4f6' : 'white', justifyContent: 'flex-start' }}>
                    {/* id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, received6, issued7, stock_qty8, reserve_stock9, pro_mrp10, exp_month11, exp_year12 */}
                    {/* id0, dp_id1, prod_Id2, pro_name3, unit4, opening_stock5, p_qty6, f_qty7, s_qty8, ''9, pro_mrp10, exp_month11, exp_year12, 
                      dist_price13, freq14, batch_no15, sp.p_qty16, sp.f_qty17, sp.s_qty18, sp_dist_price19, sp_exp_month20, sp_exp_year21 */}
                    <div style={{ width: '40%', paddingInline: '.5%', fontSize: '90%' }}>
                      <div>{item[3]?.length < 46 ? `${item[3]}` : `${item[3]?.substring(0, 42)}...`}</div>
                      <div style={{ fontSize: '80%' }}>{item[4]?.length < 46 ? `${item[4]}` : `${item[4]?.substring(0, 42)}...`}</div>
                    </div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[11]}/{item[12]}</div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{op_st}</div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_recv}</div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_iss}</div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_clos}</div>
                    <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{st_val}</div>
                  </div>
                </>

              )
            }
            )
          :
          null
        }
        
        <div style={{ marginInline: '.5%', borderBlock: '1px solid gray', width: '99%', display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ width: '40%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', fontWeight: 'bold' }}>Totals</div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}></div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{Math.round(col1)}</div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{Math.round(col2)}</div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{Math.round(col3)}</div>
          <div style={{ width: '10%', paddingTop: '.5%', paddingBottom: '.5%', borderRight: '1px solid gray', fontSize: '90%', textAlign: 'center' }}>{Math.round(col4)}</div>
        </div>
      </div>

    </>
  )
}

export default DPSalesStockState
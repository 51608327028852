import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Containers/Loader';
import Oops from '../Containers/Oops';

// import Select from 'react-select';
// import { components } from 'react-select';

ReactSession.setStoreType("localStorage");

const DigiPartneRetailOrders = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [idx, setIdx] = useState(0);
  const [digiPartOrders, setDigiPartOrders] = useState(null);
  const [fillDigiPartOrders, setFillDigiPartOrders] = useState(null);
  const [digiPartOrderDetails, setDigiPartOrderDetails] = useState(null);

  const [showOrderDetailsPage, setShowOrderDetailsPage] = useState(false);

  const getDPOrders = (dtFrom = null, dtTo = null) => {
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?digiOrders=3&user_Id=' + ReactSession.get('id')
    if (dtFrom != null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      
      if (resp.status === "success") {
        setDigiPartOrders(resp.allOrders);
        setFillDigiPartOrders(resp.allOrders);
      }
      setLoader(false);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);
  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  const getDPOrderDetails = (orderID, idx) => {
    setIdx(idx);
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?dpOrderDetail=1&orderId=' + orderID + '&user_Id=' + ReactSession.get('id')
    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      
      if (resp.status === "success") {
        setDigiPartOrderDetails(resp.OrdersDetails);
      }
      setLoader(false);
      setShowOrderDetailsPage(true);
    }).catch((Error) => {
      console.log("Error " + Error);
    });
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getDPOrders();
        }
  }, []);


  const handelProductDetailsDropDown = (target) => {
    let allElems = document.querySelectorAll(".productDetailsDropDownContainer");
    // console.log("ALL : ", allElems);
    Array.from(allElems).forEach((element, i) => {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
      else {
        //  element.classList.add("active")
      }
    })
    let elem = document.querySelector(target);
    elem.classList.toggle("active");
  }

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >


        <div className='DigiDashPanel' id='DigiDashPanel' >
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent digiPartOrdersContainer' id='DigiDashContent' style={{ backgroundColor: 'white' }}>

          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div style={{ margin: '20px' }}>
            <div className='digiPartOrders' style={{ width: '100%', paddingBottom: '15px' }}>
              <div>
                {/* { 
                  digiPartOrderDetails !== null ? 
                  <img onClick={() => setDigiPartOrderDetails(null)} src={require("../../static/DPImages/back.png")} alt="" className="backBtn" />
                  :null
                } */}
                {
                digiPartOrderDetails !== null ?
                <img onClick={() => {
                  setDigiPartOrderDetails(null);
                  setShowOrderDetailsPage(false);
                }} src={require("../../static/DPImages/back.png")} alt="" className="backBtn" />
                : null
                }
                <h2 style={{marginLeft:digiPartOrderDetails !== null ? "42px" : "0px"}}>My Orders</h2>
                {
                  showOrderDetailsPage === false ?
                    <img src={require("../../static/DPImages/calendar.png")} alt="" />
                    : null
                }
                {/* <div onClick={() => setDigiPartOrderDetails(null)} >Back</div> */}
              </div>


              {
                showOrderDetailsPage === false ?
                  <div className='digiOrdersSearch'>

                    {/* data[2]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                      data[3]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                      data[4]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                      data[5]?.toLowerCase().includes(e.target.value?.toLowerCase()) */}


                    <input type='text' name="digiOrderSearchTxt" id="digiOrderSearchTxt" placeholder='Search by transaction id..' onChange={(e) => {
                      const filData = digiPartOrders.filter((data) =>
                        String(data[0])?.toLowerCase().includes(e.target.value?.toLowerCase())
                      );
                      setFillDigiPartOrders(filData);
                    }
                    } />
                    <i className="ri-search-line"></i>
                  </div> : null

              }

              {
                showOrderDetailsPage === false ?
                  <div className="date">
                    <button>DD-MM-YYYY</button>
                    <img src={require("../../static/DPImages/calendar.png")} alt="" />
                  </div> : null
              }
            </div>


            {
              digiPartOrderDetails !== null ?
              digiPartOrderDetails === null && fillDigiPartOrders.length == 0 ? <>
                <Oops title={"No Records Found !"}/>
              </> : "" : ""
            }

            {
              digiPartOrderDetails === null ?
                <div>
                  {
                    fillDigiPartOrders && fillDigiPartOrders !== null ?
                      fillDigiPartOrders.map((data, index) => {
                        {/* style={{ backgroundColor: index % 2 === 1 ? '#ededed' : 'white' }} */}
                        return (
                          <div className='digiPartOrdersCard' key={index} >
                            <div className='digiPartOrdersCardRight'>
                              <div>Order Date & Time : {data[6].split('T')[0]} {data[6].split('T')[1]}</div>
                              <div>Transaction Id : {data[0]}</div>
                              <div>Order to : {data[10]}</div>
                              <div style={{ marginBottom: "15px" }}>Quantity : {data[2]}</div>

                              <div className='digiPartOrdersCardDetails'>
                                <h3 style={{ margin: "0px", padding: "0px" }}>Estimated MRP Value : {Math.round(data[3])} | <span style={{color:"black",fontSize:"16px"}}>Estimated Payable Value : {Math.round(data[4])}</span></h3>
                                {/* <h3 style={{ margin: "0px", padding: "0px", marginTop: "5px", color: "black" }}> </h3> */}
                              </div>
                              <Link className='digiPartOrdersCardButtonDetails' onClick={() => { getDPOrderDetails(data[0], index) }}>Details</Link>
                            </div>
                          </div>
                        )
                      })
                      : 
                      <>
                        <Oops title="No records found...!" />
                      </>
                  }
                </div>
                :
                <div>
                  {
                    digiPartOrderDetails.map((orderDetails, index) => {
                      {/* var orderItems = Array(orderDetails[14]); */ }

                      // Remove the leading and trailing brackets and split by '),('
                      const nestedArrayString = orderDetails[14].slice(1, -1).split('),(');

                      // Map over each item in the nested array string and split by comma
                      const orderItems = nestedArrayString.map(item => {
                        // Split by comma and trim spaces
                        const values = item.split(',').map(value => value.trim());
                        // Remove any empty strings
                        return values.filter(value => value !== '');
                      });

                      return (
                        <div className={index % 2 === 0 ? 'digiPartOrderDetailsGray digiOrderDet' : 'digiPartOrderDetails digiOrderDet'} >
                          {
                            index < 1 ?
                            <>
                              <div className='detailsTop'>
                                <p style={{ margin: "0px", padding: "0px" }}>Order Date & Time : {orderDetails[6]}</p>
                                <p style={{ margin: "0px", padding: "0px" }}>Transaction Id : {orderDetails[0]}</p>
                              </div>

                              <div className='digiPartTopSecond'>
                                <div>
                                  <p className='vendorName' style={{ color: "black", margin: "0px" }}>{orderDetails[9]} | Bhopal</p>
                                  <p style={{ margin: "0px" }}>{orderDetails[12]}</p>
                                  <p style={{ fontSize: "16px" }}>{orderDetails[13]}</p>
                                  <p style={{ margin: "0px", padding: "0px", marginTop: "-15px" }}>Estimated MRP Value : {Number(orderDetails[15]).toFixed(2)} <span className='pipeSym'>|</span> <span>Estimated PTR : {Number(orderDetails[8]).toFixed(2)}</span></p>

                                </div>

                                <div>
                                  <p style={{ margin: "0px", padding: "0px" }}>Status : Pending</p>
                                  <button className="dpVendSerPro" onClick={() => handelProductDetailsDropDown(`#productDetails${index}`)}>Details <i className="ri-arrow-down-s-line"></i></button>
                                </div>
                              </div>
                            </>
                            :
                            digiPartOrderDetails[index - 1][9] !== orderDetails[9] ?
                              <>
                                <div className='digiPartTopSecond'>
                                  <div>
                                    <p className='vendorName' style={{ color: "black", margin: "0px" }}>{orderDetails[9]} | Bhopal</p>
                                    <p style={{ margin: "0px" }}>{orderDetails[12]}</p>
                                    <p style={{ fontSize: "16px" }}>{orderDetails[13]}</p>
                                    <p style={{ marginBottom: "0px", padding: "0px", marginTop: "-15px" }}>Estimated MRP Value : {Number(orderDetails[15]).toFixed(2)} <span className='pipeSym'>|</span> <span>Estimated PTR : {Number(orderDetails[8]).toFixed(2)}</span></p>

                                  </div>

                                  <div>
                                    <p style={{ margin: "0px", padding: "0px" }}>Status : Pending</p>
                                    <button className="dpVendSerPro" onClick={() => handelProductDetailsDropDown(`#productDetails${index}`)}>Details <i className="ri-arrow-down-s-line"></i></button>
                                  </div>
                                </div>
                                {/* <p >{orderDetails[11]}</p> */}
                                {/* <p >{orderDetails[12]}</p> */}
                                {/* <p >{orderDetails[13]}</p> */}
                              </>
                              : null
                          }

                          <div className='allVal'>
                            <div className="productDetailsDropDownContainer" id={`productDetails${index}`}>
                              {
                                orderItems ? orderItems.map((item, index1) => {
                                  return (
                                    <div className="digiOrderItem" >
                                      <div>
                                        <div style={{ color: "black", width: "100%" }}>{item[3]}</div>
                                        <div style={{ width: "100%" }}>{item[4]}</div>
                                        <div style={{ width: "100%" }}>{item[5]}</div>
                                      </div>

                                      <div className='center'>
                                        <div style={{ width: "100%" }}> {item[6]} <span>* PTR : {Number(item[7]).toFixed(2)}</span> </div>
                                      </div>

                                      <div className='right'>
                                        <div style={{ width: "100%" }}>MRP : {Number(item[8] * item[6]).toFixed(2)} &nbsp;| &nbsp;<span style={{ color: "black" }}>PTR : {Number(item[7] * item[6]).toFixed(2)}</span></div>
                                      </div>
                                    </div>
                                  )
                                })
                                :
                                null
                              }
                            </div>
                          </div>

                        </div>
                      )
                    })
                  }

                  <>
                    <div className="allEstimatedValues">
                      <h4>Estimated order MRP : {Math.round(digiPartOrders[idx][3])} &nbsp; | &nbsp;</h4>
                      <h4>Estimated order PTR : {Math.round(digiPartOrders[idx][4])}</h4>
                    </div>
                  </>

                  {/* <div className='btnPrimary2' onClick={() => setDigiPartOrderDetails(null)}>Close</div> */}
                </div>
            }
          </div>

        </div>
        {loader ? <Loader /> : null}
      </div>
    </>
  )
}

export default DigiPartneRetailOrders